<template>
    <div>
        <vx-card class="mb-24">
            <div class="flex justify-between mb-10">
                <h3>Lista de cupones</h3>

                <vs-button @click="refreshList()" color="transparent" text-color="gray">
                    <feather-icon icon="RefreshCwIcon" :class="{ 'animate-spin': loading }"></feather-icon>
                </vs-button>
            </div>

            <div class="flex justify-end w-full">
                <!-- eslint-disable -->
                <vs-select :label="this.$t('Items por página')" v-model="maxItems" class="w-24">
                    <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
                </vs-select>
            </div>

            <vs-table search :max-items="maxItems" pagination :data="coupons" :noDataText=" $t( 'Datos no disponibles' ) ">

                <template slot="thead">
                    <vs-th>Código</vs-th>
                    <vs-th>Nombre</vs-th>
                    <vs-th>Precio</vs-th>
                    <vs-th>Fecha inicio</vs-th>
                    <vs-th>Fecha fin</vs-th>
                    <vs-th></vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
                        <!-- coupon code -->
                        <vs-td :data="data[indextr].code">
                            {{data[indextr].code}}
                        </vs-td>

                        <!-- coupon name -->
                        <vs-td :data="data[indextr].name">
                            {{data[indextr].name}}
                        </vs-td>

                        <!-- coupon price -->
                        <vs-td :data="data[indextr].price">
                            {{data[indextr].price | coin}}
                        </vs-td>

                        <!-- coupon start date -->
                        <vs-td :data="data[indextr].start_date_prog">
                            {{data[indextr].start_date_prog}} - {{data[indextr].start_hour}}
                        </vs-td>

                        <!-- coupon code -->
                        <vs-td :data="data[indextr].end_date_prog">
                            {{data[indextr].end_date_prog}} - {{data[indextr].end_hour}}
                        </vs-td>

                        <!-- actions -->
                        <vs-td :data="data[indextr].id">
                            <vs-button size="small" color="transparent" @click.stop="openSideBarEdit( data[indextr].code )">
                                <img :src="require(`@/assets/icons/EditBlueIcon.svg`)" width="15px" />
                            </vs-button>
                            
                            <!-- boton eliminar -->
                            <!-- <vs-button size="small" color="transparent" @click.stop="openDialog( data[indextr].code )" :disabled="data[indextr].edit">
                                <img :src="require(`@/assets/icons/DeleteBlueIcon.svg`)" width="15px" />
                            </vs-button> -->
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>

        <edit :coupon_code="coupon_code" :sidebar="sidebar" @closeSidebar="closeSidebar"/>

        <!-- modal eliminar -->
        <vs-prompt
            @accept="del()"
            @close="closeDialog()"
            :active.sync="dialog"
            title="Eliminar Cupón"
            buttons-hidden
        >
            <div class="w-full">
                <vs-button  class="float-right" @click="closeDialog()" color="transparent" text-color="#666">
                    <feather-icon icon='XIcon'  />
                </vs-button>
            </div>
            
            <div class="w-full flex justify-center mb-5">
                <img :src="require('@/assets/images/elements/Trash.svg')" alt="Delete Icon" width="128px" />
            </div>
            
            <div class="text-center text-gray-400">
                <h3 class="mb-2"> Eliminar cupón seleccionado </h3>
                <p>
                    <span>¿Estás seguro que deseas eliminar definitivamente el cupón seleccionado?</span>
                </p>

                <p><vs-button class="mt-5 rounded-full" @click="del()">Eliminar Definitivamente</vs-button></p>
                <p><vs-button  class="mt-2 text-primary" @click="closeDialog()" color="#FFF">Cancelar</vs-button></p>
            </div>
        </vs-prompt>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Edit from './Edit.vue';

export default Vue.extend({

    data() {
        return {
            loading   : false,
            coupon_code : '',
            coupons_selected_code : [],
            sidebar   : false,
            dialog    : false,
            maxItems     : 50,
            maxItemsVals : [5,10,15,20,25,30,35,40,45,50],
        }
    },
    
    created() {
        this.refreshList();
    },

    methods :  {
        async refreshList() {
            this.loading = true;
            await this.$store.dispatch('promotions/getAllCoupons');
            this.loading = false;
        },

        openSideBarEdit( coupon_code='' ) {
            this.coupon_code = coupon_code;
            this.sidebar = true;
        },

        closeSidebar(ev) {
            if( ev ) return;

            this.sidebar = false;
            this.coupon_code = '';
        },

        openDialog(coupon_code='') {
            this.coupons_selected_code.push(coupon_code);
            this.dialog = true;
        },

        closeDialog() {
            this.coupons_selected_code = [];
            this.dialog = false;
        },

        async del() {
            for(let coupon_id of this.coupons_selected_code) {
                this.$vs.loading();
                await this.$store.dispatch('promotions/deleteCoupon', coupon_id);
                this.$vs.loading.close();
            }
            this.coupons_selected_code = [];
            this.dialog = false;
        },
    },

    computed : {
        coupons() {
            return this.$store.state.promotions.coupons;
        }
    },

    components : {
        Edit,
    }
})
</script>
