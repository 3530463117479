<template>
    <vs-sidebar click-not-close position-right parent="#app" class="coupons-sidebar" spacer v-model="localActiveSidebar">
        <div class="mt-6 flex items-center justify-between px-6" slot="header">
            <h4 class="text-white"> {{ this.$t('Modificar Cupón') }} </h4>
            <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
        </div>
        
        <div v-if="loading" class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
        
        <VuePerfectScrollbar v-else class="scroll-area--data-list-add-new px-5">
            <form @submit.prevent="saveCoupon()" id="formCoupon">
                <div class="vx-row mb-24">
                    <!-- coupon code -->
                    <div class="vx-col w-full">
                        <vs-input
                            v-model="coupon.code"
                            :label="$t('Código')"
                            class="w-full"
                            disabled
                        />
                    </div>

                    <!-- coupon name -->
                    <div class="vx-col w-1/2 mt-5">
                        <vs-input
                            v-model="coupon.name"
                            :label="$t('Nombre')"
                            val-icon-danger="clear"
                            class="w-full"
                            :danger="!!validate.nameError"
                            :danger-text="validate.nameError"
                            required
                        />
                    </div>

                    <!-- coupon price -->
                    <div class="vx-col w-1/2 mt-5">
                        <vs-input
                            v-model="coupon.price"
                            :label="$t('Precio')"
                            val-icon-danger="clear"
                            icon="attach_money"
                            class="w-full"
                            :danger="!!validate.priceError"
                            :danger-text="validate.priceError"
                            type="number"
                            required
                        />
                    </div>

                    <!-- coupon text -->
                    <div class="vx-col w-full mt-5">
                        <vs-textarea
                            v-model="coupon.text"
                            :label="$t('Descripción')"
                            class="w-full"
                            :danger="!!validate.descriptionError"
                            :danger-text="validate.descriptionError"
                            required
                        />
                    </div>

                    <!-- start date -->
                    <div class="vx-col w-1/2">
                        <label for="date_init">{{ this.$t('Fecha de inicio') }}</label><br/>
                        <flat-pickr
                            v-model="coupon.start_date_prog"
                            :config="configFromdateTimePicker"
                            :placeholder="$t('Fecha de inicio')"
                            @on-change="onFromChange"
                            id="date_init"
                            class="vs-input--input"
                        />
                    </div>

                    <!-- start hour -->
                    <div class="vx-col w-1/2">
                        <label for="hour_init">{{ this.$t('Hora de inicio') }}</label><br/>
                        <flat-pickr
                            v-model="coupon.start_hour"
                            :config="configTime"
                            :placeholder="$t('Hora de inicio')"
                            @on-change="validateInitHour"
                            id="hour_init"
                            class="vs-input--input"
                        />
                    </div>

                    <!-- end date -->
                    <div class="vx-col w-1/2 mt-5">
                        <label for="end_date">{{ this.$t('Fecha de finalización') }}</label><br/>
                        <flat-pickr
                            v-model="coupon.end_date_prog"
                            :config="configTodateTimePicker"
                            :placeholder="$t('Fecha de finalización')"
                            @on-change="onToChange"
                            id="end_date"
                            class="vs-input--input"
                        />
                    </div>

                    <!-- end hour -->
                    <div class="vx-col w-1/2 mt-5">
                        <label for="end_hour">{{ this.$t('Hora de finalización') }}</label><br/>
                        <flat-pickr
                            v-model="coupon.end_hour"
                            :config="configTime"
                            :placeholder="$t('Hora de finalización')"
                            @on-change="validateEndHour"
                            id="end_hour"
                            class="vs-input--input"
                        />
                    </div>

                    <!-- coupon minimum purchase -->
                    <div class="vx-col w-1/2 mt-5">
                        <vs-input
                            v-model="coupon.minimum_purchase"
                            :label="$t('Precio mínimo de compra')"
                            icon="attach_money"
                            class="w-full"
                            type="number"
                        />
                    </div>

                    <!-- visibilidad -->
                    <div class="vx-col w-1/2 mt-5">
                        <vs-select
                            class="w-full"
                            :label="$t('Privacidad')"
                            v-model="coupon.view"
                        >
                            <vs-select-item :key="item.id" :value="item.id" :text="item.value" v-for="item in viewOptions" />
                        </vs-select>
                    </div>

                    <!-- coupon shop -->
                    <div class="vx-col w-full mt-5">
                        <label for="shop_id">{{ this.$t('Seleccione una tienda') }}</label><br/>
                        <v-select
                            :options="stores"
                            :reduce="item => item.id"
                            :appendToBody="false"
                            v-model="coupon.shop_id"
                            label="name"
                            id="shop_id"
                            class="z-50"
                            :placeholder="$t('Seleccione una tienda')"
                        >
                        </v-select>
                    </div>

                    <!-- coupon identification -->
                    <div class="vx-col w-full mt-5">
                        <vs-input
                            v-model="coupon.identification"
                            :label="$t('Número de Identificación de cliente')"
                            class="w-full"
                        />
                    </div>

                    <!-- status -->
                    <div class="vx-col w-full mt-5">
                        <vs-select
                            class="w-full"
                            :label="$t('Estado')"
                            v-model="coupon.status"
                        >
                            <vs-select-item :key="item.id" :value="item.id" :text="item.value" v-for="item in statusOptions" />
                        </vs-select>
                    </div>
                </div>
            </form>
        </VuePerfectScrollbar>
            
        <div class=" items-center p-6" slot="footer">
            <button type="button" class="vs-component vs-button vs-button-danger vs-button-border" @click="localActiveSidebar = false">
                {{ this.$t('Cancelar') }}
            </button>

            <button type="submit" form="formCoupon" class="vs-component vs-button vs-button-info vs-button-filled float-right">
                {{ this.$t('Guardar') }}
            </button>
        </div>
    </vs-sidebar>
</template>

<script lang="ts">
import Vue                 from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect             from 'vue-select';
import flatPickr           from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import { Validation } from '../../../plugins/validation';
import utils          from '../../../plugins/utils';

export default Vue.extend({
    props: {
        sidebar: { required: true, type: Boolean, },
        coupon_code: { required: true, type: String },
    },

    data() {
        return {
            loading : true,
            validate: new Validation,
            utils,
            configFromdateTimePicker : {
                minDate: null,
                maxDate: null
            },
            configTodateTimePicker: {
                minDate: null
            },
            configTime : {
                enableTime: true,
                enableSeconds: true,
                noCalendar: true,
                dateFormat: "H:i:s",
            },
            viewOptions : [
                { id: 0, value: 'No' },
                { id: 1, value: 'Sí' },
            ],
            statusOptions : [
                { id: 0, value: 'Inactivo' },
                { id: 1, value: 'Activo' },
                { id: 2, value: 'Privado' },
            ],
        }
    },

    watch: {
        async coupon_code(val) {
            if( !val ) return;
            
            this.loading = true;
            await this.$store.dispatch('promotions/getCoupon', val);
            await this.$store.dispatch('stores/getAllStores');
            this.loading = false;
        },
        'coupon.name'(val) { this.validate.validateName( val ); },
        'coupon.price'(val) { this.validate.validatePrice( val ); },
        'coupon.text'(val) { this.validate.validateDescription( val ); },
    },

    methods: {
        async saveCoupon() {
            const isValidSKU = this.validate.validateSKU( this.coupon.code );
            const isValidName = this.validate.validateName( this.coupon.name );
            const isValidPrice = this.validate.validatePrice( this.coupon.price );
            const isValidDescription = this.validate.validateDescription( this.coupon.text );
            
            if( !isValidSKU || !isValidName || !isValidPrice || !isValidDescription ) {
                this.$vs.notify({
                    title: this.$t('Valide los campos antes de continuar'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7100,
                });
                return;
            }

            this.$vs.loading();

            const payload = {
                ...this.coupon,
                end_hour: this.coupon.end_hour.trim(),
                start_hour: this.coupon.start_hour.trim(),
            }
            
            const res = await this.$store.dispatch('promotions/updateCoupon', payload);
            
            this.$vs.loading.close();
            
            if( res ) this.localActiveSidebar = false;
        },

        validateInitDate( dateStr='' ) {
            const dateSplit = dateStr.split('-');
            const now = new Date();
            const nowTs = now.getTime();
            const hour = utils.dateToStr(now).substring(10, 18);
            const hrSplit = hour.split(':');

            const dateTs = new Date(
                parseInt(dateSplit[0]),
                parseInt(dateSplit[1]) - 1,
                parseInt(dateSplit[2])
            ).setHours( parseInt(hrSplit[0]), parseInt(hrSplit[1] + 2) );

            if( nowTs > dateTs ) {
                this.$vs.notify({
                    title: this.$t('Seleccione una fecha superior a la actual'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7100,
                });

                setTimeout(() => {
                    this.coupon.start_date_prog = utils.dateToStr(now).substring(0, 10);
                    this.$set(this.configTodateTimePicker, 'minDate', this.coupon.start_date_prog);
                }, 1);
            }
            
        },

        validateInitHour(ev) {
            if( ev.length === 0 ) return;
            
            const dateSplit = this.coupon.start_date_prog.split('-');
            const date = new Date(dateSplit[0], dateSplit[1] -1, dateSplit[2]);
            date.setHours( ev[0].getHours() );
            date.setMinutes( ev[0].getMinutes() );
            date.setSeconds( ev[0].getSeconds() );
            
            const nowTs = new Date().getTime();
            const dateTs = date.getTime();

            if( nowTs > dateTs ) {
                this.$vs.notify({
                    title: this.$t('Seleccione una hora superior a la actual'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7100,
                });

                const fiveMinutes = new Date();
                fiveMinutes.setMinutes( new Date().getMinutes() +20 );

                const fiveMinutesStr = this.utils.dateToStr( fiveMinutes );
                const fiveMinutesSub = fiveMinutesStr.substring(10, 19);

                setTimeout(() => {
                    this.coupon.start_hour = fiveMinutesSub;
                }, 3000);
                
            }            
        },

        validateEndHour(ev) {
            if( ev.length === 0 ) return;
            
            const dateInitSplit = this.coupon.start_date_prog.split('-');
            const hourInitSplit = this.coupon.start_hour.split(':');
            const dateInit = new Date(dateInitSplit[0], dateInitSplit[1] -1, dateInitSplit[2]);
            dateInit.setHours( hourInitSplit[0] );
            dateInit.setMinutes( hourInitSplit[1] );
            dateInit.setSeconds( hourInitSplit[2] );

            const dateInitTs = dateInit.getTime();
            
            const dateEndSplit = this.coupon.end_date_prog.split('-');
            const dateEnd = new Date(dateEndSplit[0], dateEndSplit[1] -1, dateEndSplit[2]);
            dateEnd.setHours( ev[0].getHours() );
            dateEnd.setMinutes( ev[0].getMinutes() );
            dateEnd.setSeconds( ev[0].getSeconds() );
            
            const endDateTs = dateEnd.getTime();
            

            if( dateInitTs > endDateTs ) {
                this.$vs.notify({
                    title: this.$t('Seleccione una hora superior a la de inicio'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7100,
                });

                const fiveMinutes = new Date( dateInitTs );
                fiveMinutes.setMinutes( new Date().getMinutes() + 90 );

                const fiveMinutesStr = this.utils.dateToStr( fiveMinutes );
                const fiveMinutesSub = fiveMinutesStr.substring(10, 19);

                setTimeout(() => {
                    this.coupon.end_hour = fiveMinutesSub;
                }, 3000);
                
            }
        },

        /* eslint-disable */
        onFromChange(selectedDates, dateStr, instance) {
            this.validateInitDate(dateStr);
            this.$set(this.configTodateTimePicker, 'minDate', dateStr);
        },

        /* eslint-disable */
        onToChange(selectedDates, dateStr, instance) {
            this.$set(this.configFromdateTimePicker, 'maxDate', dateStr);
        },
    },

    computed: {
        localActiveSidebar: {
            get: function() {
                return this.sidebar;
            },
            set: function( val ) {                
                this.$emit( 'closeSidebar', val );
                this.$store.dispatch( 'promotions/getAllCoupons' );
                this.$store.commit('promotions/CLEAR_COUPON');
            }
        },
        coupon : {
            get() { // return coupon proxy
                const store = this.$store;
                const coupon = store.state.promotions.coupon;
                let couponProxy = new Proxy( coupon, {
                    set (obj, key, value) {
                        store.commit('promotions/SET_COUPON', {
                            ...obj,
                            [key]: value,
                        });    
                        return true
                    }
                });

                return couponProxy;
            },
            set( val ) { this.$store.commit('promotions/SET_COUPON', val); }
        },
        stores() {
            return this.$store.state.stores.stores;
        },
    },

    components: {
        VuePerfectScrollbar,
        'v-select': vSelect,
        flatPickr,
    }
});
</script>

<style lang="scss" scoped>
.coupons-sidebar {
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }
    
    ::v-deep .vs-sidebar {
        position: fixed;
        z-index: 52010;
        width: 450px;
        max-width: 90vw;
        .vs-sidebar--header {
            background: #841910;
            color: #FFF;
        }
    }
}

.scroll-area--data-list-add-new {
    height: 79vh !important;
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

</style>
